@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"

@layer base
    :root
        --color-primary-50: 237 252 246
        --color-primary-100: 212 247 232
        --color-primary-200: 172 238 213
        --color-primary-300: 119 222 189
        --color-primary-400: 63 200 160
        --color-primary-500: 25 154 121
        --color-primary-600: 15 140 110
        --color-primary-700: 12 112 91
        --color-primary-800: 12 89 73
        --color-primary-900: 11 73 62
        --color-primary-950: 5 41 35

        --color-secondary-50: 242 249 253
        --color-secondary-100: 227 240 251
        --color-secondary-200: 194 226 245
        --color-secondary-300: 139 202 238
        --color-secondary-400: 78 175 226
        --color-secondary-500: 39 149 208
        --color-secondary-600: 24 119 177
        --color-secondary-700: 21 95 143
        --color-secondary-800: 21 81 119
        --color-secondary-900: 23 68 99
        --color-secondary-950: 15 44 66

        --color-tertiary-50: 253 242 246
        --color-tertiary-100: 251 232 239
        --color-tertiary-200: 249 209 224
        --color-tertiary-300: 245 172 198
        --color-tertiary-400: 238 120 160
        --color-tertiary-500: 228 73 120
        --color-tertiary-600: 211 47 89
        --color-tertiary-700: 183 31 65
        --color-tertiary-800: 151 29 55
        --color-tertiary-900: 126 29 50
        --color-tertiary-950: 77 10 24

        --color-quaternary-50: 247 246 252
        --color-quaternary-100: 240 238 249
        --color-quaternary-200: 227 223 245
        --color-quaternary-300: 205 197 237
        --color-quaternary-400: 179 164 225
        --color-quaternary-500: 148 121 210
        --color-quaternary-600: 134 98 197
        --color-quaternary-700: 118 79 178
        --color-quaternary-800: 98 66 149
        --color-quaternary-900: 82 56 122
        --color-quaternary-950: 52 35 82

        --color-quinary-50: 243 250 249
        --color-quinary-100: 215 240 238
        --color-quinary-200: 175 224 220
        --color-quinary-300: 126 202 198
        --color-quinary-400: 84 173 172
        --color-quinary-500: 58 146 146
        --color-quinary-600: 44 116 117
        --color-quinary-700: 39 92 94
        --color-quinary-800: 35 74 76
        --color-quinary-900: 33 63 64
        --color-quinary-950: 14 34 37

        --color-senary-50: 254 242 242
        --color-senary-100: 254 226 226
        --color-senary-200: 253 203 203
        --color-senary-300: 251 166 167
        --color-senary-400: 246 115 116
        --color-senary-500: 238 82 83
        --color-senary-600: 218 40 41
        --color-senary-700: 183 30 31
        --color-senary-800: 151 29 30
        --color-senary-900: 126 30 31
        --color-senary-950: 68 11 11

@font-face
    font-family: 'NTR Speciaal'
    src: url("/fonts/NTRSpeciaal.woff2") format("woff2"), url("/fonts/NTRSpeciaal.ttf") format("ttf")
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: 'NTR Speciaal Bold'
    src: url("/fonts/NTRSpeciaal-Bold.woff2") format("woff2"), url("/fonts/NTRSpeciaal-Bold.ttf") format("ttf")
    font-weight: bold
    font-style: normal
    font-display: swap

*
    box-sizing: border-box

h1,
h2,
h3
    @apply text-balance
    font-family: 'NTR Speciaal Bold', 'Source Sans Pro', sans-serif

html
    @apply font-sans

html, body
    @apply overflow-y-hidden
    height: 100%
    height: 100svh
    transition: filter 2s ease-out

main
    animation: article__animation--in 0.5s ease-out

@keyframes article__animation--in
    0%, 40%
        opacity: 0
        transform: translateY(0.25rem)
    100%
        opacity: 1
        transform: translateY(0)

@media (prefers-reduced-motion)
    @keyframes article__animation--in
        0%, 40%
            opacity: 0
        100%
            opacity: 1
            
body *:not(.ntr-ui, .ntr-ui *):focus-visible
    @apply outline outline-4 outline-primary-700 ring-4 ring-primary-200 outline-offset-2 ring-offset-4

.text-balance
    text-wrap: balance

.shadow-border-bottom

    &-sm
        position: relative

        &::after
            position: absolute
            content: ""
            width: 100%
            height: 100%
            bottom: 0
            left: 0
            top: 0
            right: 0
            box-shadow: 0px -0.125rem 0px 0px rgba(0, 0, 0, 0.15) inset, 0px 0.075rem 0px 0px rgba(200, 200, 200, 0.2) inset
            pointer-events: none
            z-index: 1

    &-md
        position: relative
        
        &::after
            position: absolute
            content: ""
            width: 100%
            height: 100%
            bottom: 0
            left: 0
            top: 0
            right: 0
            box-shadow: 0px -0.2rem 0px 0px rgba(0, 0, 0, 0.15) inset, 0px 0.125rem 0px 0px rgba(200, 200, 200, 0.2) inset
            pointer-events: none
            z-index: 1

.prose *::selection
    @apply bg-primary-600 text-white dark:bg-primary-200 dark:text-black

.prose :where(h1):not(:where([class~="not-prose"] *))
    @apply max-sm:text-3xl max-sm:border-b-2 border-b-primary-300

.prose :not(pre) :where(code):not(:where([class~="not-prose"] *))
    @apply px-2 py-1 bg-slate-100 dark:bg-slate-800 rounded-md


.prose :not(pre) :where(code):not(:where([class~="not-prose"] *))::before
    content: ""


.prose :not(pre) :where(code):not(:where([class~="not-prose"] *))::after
    content: ""


.prose :where(a):not(:where([class~="not-prose"] *))
    @apply text-primary-500 dark:text-primary-100 underline underline-offset-4 decoration-2


.prose :where(strong):not(:where([class~="not-prose"] *))
    @apply relative inline-block px-0.5 z-1 before:block before:absolute before:inset-0 before:w-[calc(100%+0.25rem)] before:h-full before:-mx-0.5 before:bg-yellow-200/40 dark:before:bg-yellow-600/30 before:-z-1 before:-skew-x-12 before:-rotate-1 before:rounded-md

    a
        @apply text-primary-800 dark:text-primary-100 font-bold

.prose :where(table):not(:where([class~="not-prose"] *))
    @apply box-border bg-primary-50 dark:bg-primary-900 rounded-2xl border-[1rem] border-b-0 border-primary-50 dark:border-primary-900

.emoji
    @apply inline-block
    height: 1.2em
    width: 1.2em
    transform: translateX(0.1em) translateY(-0.1em)

.emoji-illustration-container .emoji
    height: 1.2em
    width: 1.2em
    transform: translateX(0em) translateY(0em)

.prose :where(img.emoji):not(:where([class~="not-prose"] *))
    margin: 0 !important

.simulation-blurred-vision
    @apply filter blur-[max(0.3vh,0.3vw)] md:blur-[max(0.2vh,0.2vw)] lg:blur-[max(0.1vh,0.1vw)]

.simulation-colorblindness-tritanopia
    filter: url('/simulation-colorblindness.svg#tritanopia')

.simulation-colorblindness-deuteranopia
    filter: url('/simulation-colorblindness.svg#deuteranopia')

.simulation-colorblindness-protanopia
    filter: url('/simulation-colorblindness.svg#protanopia')

.simulation-colorblindness-achromatopsia
    filter: grayscale(1)
